import { captureException, withScope } from '@sentry/react';
import { type DefaultOptions, MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { AjaxError } from 'rxjs/ajax';

function logErrorToSentry(error: unknown) {
  return captureException(error);
}

export const queryClientOptions = {
  queries: {
    // this enables better structural sharing if the same request is fired at different times while rendering
    staleTime: 5000,
    // tbd -> refetchOnWindowFocus: process.env.NODE_ENV !== 'development',
  },
} satisfies DefaultOptions;

// TODO: importing it directly might get some unwanted side-effects
// initialize this QueryClient where the QueryClientProvider is deployed
/** @deprecated please access the queryClient via useQueryClient */
export const queryClient = new QueryClient({
  // better logging in sentry by using the cache, so we can access the mutation and query
  // https://aronschueler.de/blog/2022/12/16/generating-meaningful-issues-in-sentry-with-react-query-+-axios/
  mutationCache: new MutationCache({
    onError: (err, _variables, _context, mutation) => {
      withScope((scope) => {
        scope.setContext('mutation', {
          mutationId: mutation.mutationId,
          variables: mutation.state.variables,
        });

        if (mutation.options.mutationKey) {
          if (err instanceof AjaxError && err.status) {
            switch (err.status) {
              case 400:
              case 401:
              case 403:
              case 404:
              case 405:
              case 409:
                // merge these errors into a single sentry issue for each status to reduce overall issue count
                scope.setFingerprint([`mutation-ajax-error-40X`]);
                break;
              default:
                scope.setFingerprint([`mutation-ajax-error-${err.status.toString()}`]);
            }
          } else {
            scope.setFingerprint(
              canonicalizeQueryKeys([...mutation.options.mutationKey] as string[]),
            );
          }
        }
        if (mutation.options.mutationKey) {
          scope.setFingerprint(
            // Duplicate to prevent modification
            [...mutation.options.mutationKey] as string[],
          );
        }
        logErrorToSentry(err);
      });
    },
  }),
  queryCache: new QueryCache({
    onError: (err, query) => {
      withScope((scope) => {
        scope.setContext('query', { queryHash: query.queryHash });
        if (err instanceof AjaxError && err.status) {
          switch (err.status) {
            case 400:
            case 401:
            case 403:
            case 404:
            case 405:
            case 409:
              // merge these errors into a single sentry issue for each status to reduce overall issue count
              scope.setFingerprint([`query-ajax-error-40X`]);
              break;
            default:
              scope.setFingerprint([`query-ajax-error-${err.status.toString()}`]);
          }
        } else {
          if (err instanceof Error && err.message.includes('data is undefined')) {
            scope.setFingerprint([`query-error-data-is-undefined`]);
          } else {
            scope.setFingerprint(canonicalizeQueryKeys([query.queryHash]));
          }
        }
        logErrorToSentry(err);
      });
    },
  }),
  defaultOptions: queryClientOptions,
});

const canonicalizeQueryKeys = (queryHash: string[]): string[] =>
  queryHash.map((hash) =>
    hash
      // unify sap system
      .replaceAll(/"sapSystem":\s*"[^"]+?([^"/]+)/g, '"sapSystem":"0')
      // unify kid
      .replaceAll(/"INTERNAL_USER_UI_KEY":\s*"[^"]+?([^"/]+)/g, '"INTERNAL_USER_UI_KEY":"0')
      // strip away all numbers
      .replaceAll(/\d/g, '0')
      .replaceAll(
        // UUIDs
        /\/[\da-f]{8}-[\da-f]{4}-4[\da-f]{3}-[89ABab][\da-f]{3}-[\da-f]{12}/g,
        '<uuid>',
      ),
  );
